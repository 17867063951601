import React from 'react'
import HeroWide from '@widgets/HeroWide'
import ContentLeft from './Testimony.Content.Left'
import ContentRight from './Testimony.Content.Right'

export default (props) => (
  <HeroWide.Wrapper sx={{ py: 3 }}>
    <HeroWide.LeftColumn>
      <ContentLeft title={props.title}/>
    </HeroWide.LeftColumn>
    <HeroWide.RightColumn>
      <ContentRight content={props.content} clientName={props.clientName} />
    </HeroWide.RightColumn>
  </HeroWide.Wrapper>
)
