import React from 'react'
import { Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'
import Countdown from 'react-countdown';

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `#ffe26c`,
    borderRadius: 0,
    width: `full`,
    p: 2,
    zIndex:`5`,
    position: `-webkit-sticky`, /* Safari */
    position: `sticky`,
    top:0
  },
  left: {
    flexBasis: `1/3`,
    marginLeft: [0, `5rem`],
    textAlign: `center`
  },
  center: {
    flexBasis: `1/3`,
    color:`#24252a`,
    marginLeft:`1em`,
    textAlign: `center`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`,
    marginRight: [0, `5rem`],
    display : [`none`, `block`]
  },
  heading: {
    color: `#24252a`,
    paddingTop:'10px',
    fontWeight: `bold`,
    mb: 1
  },
  subheading: {
    color: `omega`,
    mb: [3, 0]
  },
  button:{
    color: `white`,
    bg: `#24252a`,
    borderColor: `#24252a`,
    ':hover': {
      color: `white`,
      bg: `#1f8eae`,
      borderColor: `#1f8eae`
    }
  },
  li : {
    display: `inline-block`,
    fontSize: `1.3rem`,
    listStyleType: `none`,
    padding: `1rem`,
    textTransform: `uppercase`,
    marginTop: `0.5rem`
  },
  value : {
    display: `block`,
    fontSize: `2.5rem`,
    lineHeight: `2.3rem`
  }
};

const CountdownHorizontal = (props) => {

  const renderer = ({ hours, minutes, seconds, completed }) => {
      // Render a countdown
      return (
        <ul style={{padding:0, margin:0}}>
          <li style={styles.li}><span style={styles.value} id="hours">{hours}</span>Heures</li>
          <li style={styles.li}><span style={styles.value} id="minutes">{minutes}</span>Minutes</li>
          <li style={styles.li}><span style={styles.value} id="seconds">{seconds}</span>Secondes</li>
        </ul>
      )
  };

  return (
    <Flex sx={styles.wrapper}>
      <Box sx={styles.left}>
        <Heading variant='h2' sx={styles.heading}>
          Offre à durée limitée! 
        </Heading>
        <Text sx={{color:`#24252a`, fontSize:`1.5rem`}}>Formation à prix réduit!</Text>
      </Box>
      <Box sx={styles.center}>
       <Countdown 
          date={Date.now() + 86399000}
          renderer={renderer}
        />
      </Box>
      <Box sx={styles.right}>
        <Button as={Link} href={props.buttonLink} type="button" variant="white" sx={styles.button}>
          J'en profite!
        </Button>
      </Box>
    </Flex>
  )
}

export default CountdownHorizontal
